import React from 'react';
import styled from 'styled-components';
import { device } from 'styles/devices';

import { ReactComponent as BigText } from 'assets/icons/bigText.svg';
import { ReactComponent as SmallText } from 'assets/icons/smallText.svg';
import { ReactComponent as BIPlogo } from 'assets/icons/BIPlogo.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  padding: 10px 0 20px 20px;

  svg {
    cursor: pointer;
  }

  @media ${device.mobileL} {
    padding-bottom: 0;
  }
`;

const HeaderButtons = ({ changeZoom }) => (
  <Wrapper>
    <div>
      <BigText onClick={() => changeZoom(0.1)} />
      <SmallText onClick={() => changeZoom(-0.1)} />
    </div>
    <a href='https://pgkim2019.ssdip.bip.gov.pl/'>
      <BIPlogo />
    </a>
  </Wrapper>
);

export default HeaderButtons;
