import React from 'react';
import { Button } from 'm-web-components';
import { css } from 'styled-components';

export const ButtonPrimary = ({ children, onClick, small }) => (
  <Button
    onClick={onClick}
    colorType='primary'
    fontWeight='600'
    padding={small ? '10px' : '20px'}
    margin='20px 0'
    onHover={css`
      background-color: #e2c351;
    `}
    style={{
      border: 'none',
      boxShadow: '(1px 5px 20px rgba(242, 220, 138, 0.7)',
    }}
  >
    {children}
  </Button>
);
