import styled from 'styled-components';
import { device } from 'styles/devices';

export const FooterBottom = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.primary};
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-bottom: 100px;

  @media ${device.tablet} {
    flex-direction: row;
    padding: 50px;
  }
`;

export const FooterData = styled.div`
  @media ${device.tablet} {
    padding-right: 40px;
    border-right: 2px solid rgba(255, 255, 255, 0.2);
  }
`;

export const FooterTitle = styled.h4`
  font-weight: 600;
  font-size: 16px;
  color: white;
`;

export const FooterDesc = styled.p`
  width: 70%;
  font-size: 14px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.textLight};
`;

export const DataList = styled.ul`
  margin-bottom: 30px;
`;

export const ListItem = styled.li`
  display: flex;

  p {
    width: 55%;
    color: ${({ theme }) => theme.textLight};
    font-size: 14px;
    margin: 5px;
  }

  p:nth-child(1) {
    font-weight: 500;
    font-size: 16px;
    width: 45%;
  }
`;

export const FooterMenu = styled.div`
  @media ${device.tablet} {
    padding-left: 80px;
    max-width: 400px;
  }
`;

export const NavItemFooter = styled.li`
  a {
    display: block;
    color: #fff;
    padding: 8px 0;
    font-weight: 560;
    transition: 0.3s;

    &:hover {
      transform: translateX(20px);
    }
  }
`;

export const CategoryTitleFooter = styled.p`
  display: flex;
  align-items: center;
  color: #fff;
  padding: 8px 0;
  font-weight: 560;
  margin: 0;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    transform: translateX(20px);
  }
`;

export const ContainerCategoryFooter = styled.ul`
  transition: 0.3s;
  overflow: hidden;
  max-height: ${({ hover }) => (hover ? '200px' : 0)};
  opacity: ${({ hover }) => (hover ? 1 : 0)};

  a {
    color: ${({ theme }) => theme.textLight};
    font-weight: 400;
    font-size: 14px;
    transition: 0.3s;

    &:hover {
      transform: translateX(20px);
    }
  }
`;

export const Mayeryn = styled.div`
  position: absolute;
  bottom: 10px;
  right: 40px;
  text-align: right;

  p {
    color: #9e9e9e;
    font-size: 0.8rem;
  }
`;
