import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { device } from 'styles/devices';
import { apiEndpoints, sendRequest } from 'API';

import Indicator from 'components/atoms/Indicator';
import SearchIcon from '@material-ui/icons/Search';
import PageTitle from 'components/atoms/PageTitle';
import Informator from 'components/Informator/Informator';

const Wrapper = styled.main`
  padding: 0px;
  @media ${device.desktop} {
    padding: 20px;
  }
`;

const Search = styled.form`
  display: flex;
  width: 100%;
  max-width: 750px;
`;

const Input = styled.input`
  border: 2px solid #e5e5e5;
  border-radius: 4px 0 0 4px;
  padding: 10px;
  flex-grow: 1;
  font-size: 12px;

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

const SarchSubmit = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 0 4px 4px 0;

  svg {
    fill: white;
  }
`;

const NoResults = styled.p`
  margin-top: 50px;
  font-style: italic;
`;

const News = () => {
  const [posts, setPosts] = useState([]);
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    const get = async () => {
      const { data } = await sendRequest.get(apiEndpoints.posts);

      setPosts(data);
    };
    get();
  }, []);

  const onSubmit = async ({ searchValue }) => {
    const { data } = await sendRequest.get(
      apiEndpoints.searchPosts(searchValue)
    );

    setPosts(data);

    // do rozszrzenia wyszukiwania również stron
    // const { data: data2 } = await sendRequest.get(
    //   apiEndpoints.searchPages(searchValue)
    // );
    // const helperArray = [];
    // helperArray.push(...data);
    // helperArray.push(...data2);
    // setPosts(helperArray);
  };

  return (
    <Wrapper>
      {!posts ? (
        <Indicator />
      ) : (
        <>
          <PageTitle content='Aktualności' />
          <Search onSubmit={handleSubmit(onSubmit)}>
            <Input
              placeholder='Szukaj artykułów, informacji, wiadomości'
              ref={register}
              name='searchValue'
            />
            <SarchSubmit type='submit'>
              <SearchIcon />
            </SarchSubmit>
          </Search>
          {posts.length > 0 ? (
            <Informator posts={posts} />
          ) : (
            <NoResults> Niestety, nie znaleziono żadnych wyników...</NoResults>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default News;
