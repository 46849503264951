import React from 'react';
import parse from 'html-react-parser';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ButtonPrimary } from 'components/atoms/Button';
import { SectionWrapper, Image, ImageWrapper } from './Home.css';

const Section1 = ({ data, executeScroll }) => {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const images = Object.values(data.s1_photos);
  const sliders = images.map(image => (
    <Image main src={image.url} alt={image.alt} />
  ));

  return (
    <SectionWrapper left>
      <div>
        <h1>{data.s1_title}</h1>
        <div style={{ width: '60%' }}>{parse(data.s1_description)}</div>
        <ButtonPrimary onClick={executeScroll}>
          DOWIEDZ SIĘ WIĘCEJ
        </ButtonPrimary>
      </div>
      <ImageWrapper>
        <Slider {...settings}>{sliders}</Slider>
      </ImageWrapper>
    </SectionWrapper>
  );
};

export default Section1;
