import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { NavItem, ContainerCategory, CategoryTitle } from './Menu.css';
import SimpleElementInMenu from './SimpleMenuItems';

export const CategoryInMenu = ({ title, items }) => {
  const { pathname } = useLocation();

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    items.forEach(el => {
      if (`/${el.slug}` === pathname) setIsActive(true);
    });

    return () => {
      setIsActive(false);
    };
  }, [pathname]);

  const [isHover, setIsHover] = useState(false);
  const anchorRef = React.useRef(null);

  const listItems = items.map(el => (
    <SimpleElementInMenu
      key={el.slug}
      title={el.title}
      url={el.url.includes('strony/') ? `strony/${el.slug}` : el.slug || el.url}
    />
  ));

  return (
    <NavItem
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => setIsHover(prev => !prev)}
    >
      <div
        ref={anchorRef}
        aria-controls={isHover ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
      >
        <CategoryTitle isactive={isActive}>
          {title} <ArrowDropDownIcon />
        </CategoryTitle>
        <ContainerCategory hover={isHover} isactive={isActive}>
          {listItems}
        </ContainerCategory>
      </div>
    </NavItem>
  );
};
