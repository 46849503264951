/* eslint-disable array-callback-return */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Container } from '@material-ui/core';
import { device } from 'styles/devices';
import theme from 'styles/theme';
import GlobalStyle from 'styles/GlobalStyle';

import ScrollToTop from 'hooks/ScrollToTop';

import SingleInfo from 'components/Informator/SingleInfo';
import Contact from 'pages/Contact';
import Home from 'pages/Home';
import PriceLists from 'pages/PriceLists';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import PageTheme from 'pages/PageTheme';
import Garbage from 'pages/Garbage';
import News from 'pages/News';
import WaterTesting from 'pages/WaterTesting';
import NotFound from 'pages/NotFound';
import AboutUs from 'pages/AboutUs';

const Wrapper = styled.div`
  min-height: 100vh;
  padding-top: 60px;
  zoom: ${({ zoom }) => zoom};

  @media ${device.desktop} {
    padding-top: 140px;
  }
`;

function App() {
  const [zoom, setZoom] = useState(1);

  const changeZoom = value => {
    setZoom(prev => prev + value);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Wrapper className='App' zoom={zoom}>
        <Router>
          <Header changeZoom={changeZoom} />
          <ScrollToTop />
          <Container maxWidth='lg'>
            <Switch>
              <Route exact path='/strony/:slug' component={PageTheme} />
              <Route
                path='/wp-admin'
                component={() => {
                  window.location.href =
                    'https://terespolwp.mayeryn.com/wp-admin';
                  return null;
                }}
              />
              <Route exact path='/' component={Home} />
              <Route exact path='/cenniki' component={PriceLists} />
              <Route exact path='/o-nas' component={AboutUs} />
              <Route exact path='/kontakt' component={Contact} />
              <Route exact path='/aktualnosci' component={News} />
              <Route exact path='/aktualnosci/:id' component={SingleInfo} />
              <Route exact path='/badania-wody' component={WaterTesting} />
              <Route exact path='/odpady' component={Garbage} />
              <Route
                exact
                path='/zaklad-wodno-kanalizacyjny'
                component={PageTheme}
              />
              <Route
                exact
                path='/administracja-domow-mieszkalnych'
                component={PageTheme}
              />
              <Route
                exact
                path='/stacja-kontroli-pojazdow'
                component={PageTheme}
              />
              <Route
                exact
                path='/zaklad-uslug-komunalnych-i-pogrzebowych'
                component={PageTheme}
              />
              <Route component={NotFound} />
            </Switch>
          </Container>
          <Footer />
        </Router>
      </Wrapper>
    </ThemeProvider>
  );
}

export default App;
