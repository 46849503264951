// its only exmaple. You can change it
const theme = {
  primary: '#0A2041',
  secondary: '#fff',
  textPosition: 'rgba(255, 255, 255, 0.6)',
  textLight: 'rgba(255, 255, 255, 0.7)',
  textGray: 'rgba(10, 32, 65, 0.5)',

  hoverBg: 'rgba(10, 32, 65, 0.2)',

  primaryButtonBg: '#F2DC8A',
  outlineButtonBg: '#fff',
  buttonRadius: '6px',
  buttonPadding: '50px',
};

export default theme;
