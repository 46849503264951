import styled from 'styled-components';
import { device } from 'styles/devices';

import Arrow from 'assets/icons/arrow.svg';

export const ContactWrapper = styled.div`
  padding: 20px;
`;

export const ContactTitle = styled.h1`
  font-weight: 600;
  font-size: 24px;

  @media ${device.desktop} {
    font-size: 48px;
  }
`;

export const ContactSubtitle = styled.h2`
  margin-top: 60px;
  font-weight: 600;
  font-size: 18px;
`;

export const ContactData = styled.div`
  margin-top: 20px;
  font-weight: 400;
  font-size: 18px;
`;

export const ContactTilesContainer = styled.div`
  width: 100%;
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 16px;
`;

export const ListItem = styled.li`
  display: block;
  list-style: none;
  background: url('${Arrow}') no-repeat left 16px;
  padding: 3px 10px 35px 35px;
  margin: 0;
  word-break: break-all;
`;

export const WrapperMap = styled.div`
  padding: 50px 0;
`;
