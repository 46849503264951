import React from 'react';
import styled from 'styled-components';
import { device } from 'styles/devices';
import ErrorImage from 'assets/images/error_404.png';
import { ButtonPrimary } from 'components/atoms/Button';

const ErrorWrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 150px;
  margin-top: 100px;
  @media ${device.desktop} {
    flex-direction: row;
    margin-bottom: 200px;
  }
`;

const ErrorContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
  max-width: 613px;
  margin: auto;
  h1 {
    font-size: 24px;
    @media ${device.desktop} {
      font-size: 48px;
    }
  }
  a {
    max-width: 300px;
  }
`;

const Image = styled.img`
  max-width: 165px;
  @media ${device.desktop} {
    max-width: 227px;
  }
`;

const NotFound = () => (
  <ErrorWrapper>
    <Image src={ErrorImage} alt='Ikona błędu' />
    <ErrorContentWrapper>
      <h1>Niestety, strona nie została odnaleziona</h1>
      <a href='/'>
        <ButtonPrimary>Przejdź do strony głównej</ButtonPrimary>
      </a>
    </ErrorContentWrapper>
  </ErrorWrapper>
);

export default NotFound;
