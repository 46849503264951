import React from 'react';
import SingleSmallInfo from './SingleSmallInfo';

const Informator = ({ posts }) => (
  <>
    {posts.map(post => (
      <SingleSmallInfo key={post.id} data={post} />
    ))}
  </>
);

export default Informator;
