import React, { useState, useEffect } from 'react';
import { apiEndpoints, sendRequest } from 'API';

import Indicator from 'components/atoms/Indicator';
import Bookmark1 from 'components/PriceLists/Bookmark1';
import Bookmark2 from 'components/PriceLists/Bookmark2';
import Bookmark3 from 'components/PriceLists/Bookmark3';
import {
  theme,
  invertedTheme,
  BookmarkButtons,
  ButtonsWrapper,
} from 'components/shared/Bookmark/BookmarkButtons.css';
import {
  PriceListsWrapper,
  PriceListsTitle,
} from 'components/PriceLists/PriceLists.css';

const PriceLists = () => {
  const [dataPage, setDataPage] = useState(null);
  const [selectedList, setSelectedList] = useState(0);
  const [arrayBookmarkData, setArrayBookmarkData] = useState(null);

  useEffect(() => {
    const get = async () => {
      const { data } = await sendRequest.get(apiEndpoints.page('cenniki'));
      setDataPage(data[0]?.acf);
    };
    get();
  }, []);

  useEffect(() => {
    if (dataPage) {
      const helpArray = Object.keys(dataPage?.bookmarks).map(
        el => dataPage.bookmarks[el]
      );

      setArrayBookmarkData(helpArray);
    }
  }, [dataPage]);

  const renderBookmark = () => {
    switch (selectedList) {
      case 0:
        return <Bookmark1 data={dataPage.bookmarks.bookmark1} />;
      case 1:
        return <Bookmark2 data={dataPage.bookmarks.bookmark2} />;
      case 2:
        return <Bookmark3 data={dataPage.bookmarks.bookmark3} />;
      default:
        return 'Unexpected behaviour';
    }
  };

  return (
    <main>
      {!arrayBookmarkData ? (
        <Indicator />
      ) : (
        <PriceListsWrapper>
          <PriceListsTitle>{dataPage.main_title}</PriceListsTitle>
          <ButtonsWrapper>
            {arrayBookmarkData.map((el, index) => (
              <BookmarkButtons
                height='54px'
                theme={index === selectedList ? invertedTheme : theme}
                key={el.title}
                onClick={() => setSelectedList(index)}
              >
                {el.title}
              </BookmarkButtons>
            ))}
          </ButtonsWrapper>
          {renderBookmark()}
        </PriceListsWrapper>
      )}
    </main>
  );
};

export default PriceLists;
