import React, { useState } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {
  NavItemFooter,
  CategoryTitleFooter,
  ContainerCategoryFooter,
} from 'components/Footer/Footer.css';
import SimpleElementInMenu from './SimpleMenuItems';

const CategoryInMenuFooter = ({ title, items }) => {
  const [isHover, setIsHover] = useState(false);

  const listItems = items.map(el => (
    <SimpleElementInMenu
      key={el.slug}
      title={el.title}
      url={el.url.includes('strony/') ? `strony/${el.slug}` : el.slug}
      footer
    />
  ));

  return (
    <NavItemFooter onClick={() => setIsHover(prev => !prev)}>
      <div
        aria-controls={isHover ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
      >
        <CategoryTitleFooter>
          {title} <ArrowDropDownIcon style={{ fill: '#fff' }} />
        </CategoryTitleFooter>
        <ContainerCategoryFooter hover={isHover}>
          {listItems}
        </ContainerCategoryFooter>
      </div>
    </NavItemFooter>
  );
};

export default CategoryInMenuFooter;
