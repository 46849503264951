import React, { useEffect, useState } from 'react';

import MayerynLogo from 'assets/logo/mayeryn.png';

import { apiEndpoints, sendRequest } from 'API';
import Indicator from 'components/atoms/Indicator';
import Menu from 'components/Menu/Menu';
import FooterTop from './FooterTop';

import {
  FooterBottom,
  FooterTitle,
  DataList,
  FooterDesc,
  ListItem,
  FooterData,
  FooterMenu,
  Mayeryn,
} from './Footer.css';

const Footer = () => {
  const [dataPage, setDataPage] = useState(null);

  useEffect(() => {
    const get = async () => {
      const { data } = await sendRequest.get(
        apiEndpoints.page('strona-glowna')
      );
      setDataPage(data[0].acf);
    };
    get();
  }, []);

  const list = dataPage?.footer_list?.split('<br />').map(el => {
    const [name, position] = el.split(' - ');
    return (
      <ListItem key={name}>
        <p>{name}: </p>
        <p>{position}</p>
      </ListItem>
    );
  });
  return (
    <>
      {!dataPage ? (
        <Indicator />
      ) : (
        <footer>
          <FooterTop />
          <FooterBottom>
            <FooterData>
              <FooterTitle>{dataPage?.footer_title}</FooterTitle>
              <FooterDesc> {dataPage?.footer_description}</FooterDesc>
              <DataList>{list}</DataList>
            </FooterData>
            <FooterMenu>
              <Menu footer />
            </FooterMenu>
            <Mayeryn>
              <p>Projekt i realizacja</p>
              <a href='https://mportal.mayeryn.com/r/i8M'>
                <img src={MayerynLogo} alt='logo-mayeryn' />
              </a>
            </Mayeryn>
          </FooterBottom>
        </footer>
      )}
    </>
  );
};

export default Footer;
