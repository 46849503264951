import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { sendRequest } from 'API';
import { ButtonPrimary } from 'components/atoms/Button';
import WYSIWYG from 'components/shared/WYSIWYG/WYSIWYG';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { device } from 'styles/devices';
import { Link } from 'react-router-dom';
import { centerFlex } from 'styles/mixins';
import { getDate } from 'helpers/getDate';
import { Date } from './Informator.css';

const Wrapper = styled.div`
  margin-top: 50px;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;

  @media ${device.tablet} {
    font-size: 30px;
  }
`;

const LinkStyled = styled(Link)`
  ${centerFlex}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: row;
  }

  img {
    max-width: 300px;
    padding: 30px;
    padding-left: 0;

    @media ${device.tablet} {
      flex-direction: row;
      margin-right: 50px;
    }
  }
`;

const SingleSmallInfo = ({ data }) => {
  const date = getDate(data.date);
  const [img, setImg] = useState(null);

  useEffect(() => {
    const get = async () => {
      const url = data['_links']['wp:featuredmedia']?.[0].href;
      const { data: image } = await sendRequest.get(url);
      setImg(url ? image : null);
    };
    get();
  }, []);

  return (
    <Wrapper>
      <Content>
        {img && <img src={img?.source_url} alt={img?.alt_text} />}
        <div>
          <Date>{date} r.</Date>
          <Title>
            <WYSIWYG content={data.title.rendered} />
          </Title>
          {data.excerpt.rendered && <WYSIWYG content={data.excerpt.rendered} />}
          <ButtonPrimary small>
            <LinkStyled to={`/aktualnosci/${data.id}`}>
              Czytaj dalej <ArrowRightIcon />
            </LinkStyled>
          </ButtonPrimary>
        </div>
      </Content>
    </Wrapper>
  );
};

export default SingleSmallInfo;
