import React, { useState, useEffect } from 'react';
import { apiEndpoints, sendRequest } from 'API';

import Indicator from 'components/atoms/Indicator';
import WYSIWYG from 'components/shared/WYSIWYG/WYSIWYG';

const Gallery = () => {
  const [dataPage, setDataPage] = useState(null);

  useEffect(() => {
    const get = async () => {
      const { data } = await sendRequest.get(apiEndpoints.page('odpady'));
      setDataPage(data[0]);
    };
    get();
  }, []);

  return (
    <main>
      {!dataPage ? (
        <Indicator />
      ) : (
        <WYSIWYG content={dataPage?.content.rendered} />
      )}
    </main>
  );
};

export default Gallery;
