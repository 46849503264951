import React from 'react';
import {
  BookmarkTableTitle,
  BookmarkTable,
  TableDataHeader,
  TableData,
} from './PriceLists.css';

const Bookmark1Table = data => (
  <>
    <BookmarkTableTitle>{data.tableData.title}</BookmarkTableTitle>
    <BookmarkTable>
      <tbody>
        <tr>
          {data.tableData.table.header.map((title, index) => (
            <TableDataHeader
              key={title.c}
              align={index === 0 ? 'left' : 'right'}
            >
              {title.c}
            </TableDataHeader>
          ))}
        </tr>
        {data.tableData.table.body.map(row => {
          const weight = row[0].c.charAt(0) === '/' ? '400' : '500';
          return (
            <tr key={row[0].c}>
              {row.map((cell, index) => (
                <TableData
                  key={data.tableData.table.header[index].c + cell.c}
                  align={index === 0 ? 'left' : 'right'}
                  weight={weight}
                >
                  {cell.c.charAt(0) === '/' ? cell.c.substr(1) : cell.c}
                </TableData>
              ))}
            </tr>
          );
        })}
      </tbody>
    </BookmarkTable>
  </>
);
export default Bookmark1Table;
