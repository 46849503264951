import React, { useEffect, useState } from 'react';
import { apiEndpoints, sendRequest } from 'API';

import Indicator from 'components/atoms/Indicator';
import BookmarkDownload from 'components/shared/Bookmark/BookmarkDownload';
import { DownloadTitle } from 'components/shared/Bookmark/BookmarkButtons.css';
import WYSIWYG from 'components/shared/WYSIWYG/WYSIWYG';

import styled from 'styled-components';
import { device } from 'styles/devices';

const WaterTestingWrapper = styled.div`
  padding: 5px;
  @media ${device.desktop} {
    padding: 20px;
  }
`;

const WaterTestingTitle = styled.h1`
  font-size: 28px;
  font-weight: 600;

  @media ${device.desktop} {
    font-size: 48px;
  }
`;

const WaterTesting = () => {
  const [dataPage, setDataPage] = useState(null);
  const [downloadData, setDownloadData] = useState(null);

  useEffect(() => {
    const get = async () => {
      const { data } = await sendRequest.get(apiEndpoints.page('badania-wody'));
      setDataPage(data[0]?.acf);

      const downloadHelpArray = Object.keys(data[0]?.acf.download).map(
        el => data[0]?.acf.download[el]
      );

      setDownloadData(downloadHelpArray);
    };
    get();
  }, []);

  return (
    <main>
      {!dataPage ? (
        <Indicator />
      ) : (
        <WaterTestingWrapper>
          <WaterTestingTitle>{dataPage.main_title}</WaterTestingTitle>
          <WYSIWYG content={dataPage.description} />
          {downloadData && (
            <>
              <DownloadTitle>{dataPage.download.title}</DownloadTitle>
              {downloadData
                .slice(1)
                .reverse()
                .map(download => (
                  <BookmarkDownload
                    key={download.display_name}
                    download={download}
                  />
                ))}
            </>
          )}
        </WaterTestingWrapper>
      )}
    </main>
  );
};

export default WaterTesting;
