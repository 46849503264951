import React from 'react';
import { NavLink } from 'react-router-dom';

import { NavItemFooter } from 'components/Footer/Footer.css';
import { NavItem } from './Menu.css';

const SimpleElementInMenu = ({ title, url, footer, noSlug }) => (
  <>
    {footer ? (
      <NavItemFooter>
        {noSlug === 'true' ? (
          <a href={url}>{title}</a>
        ) : (
          <NavLink to={`/${url}`} exact>
            {title}
          </NavLink>
        )}
      </NavItemFooter>
    ) : (
      <NavItem>
        {noSlug === 'true' ? (
          <a href={url}>{title}</a>
        ) : (
          <NavLink to={`/${url}`} exact>
            {title}
          </NavLink>
        )}
      </NavItem>
    )}
  </>
);

export default SimpleElementInMenu;
