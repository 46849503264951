import React, { useEffect, useState } from 'react';

import { BookmarkWrapper } from 'components/PriceLists/PriceLists.css';

import Indicator from 'components/atoms/Indicator';

import { DownloadTitle } from 'components/shared/Bookmark/BookmarkButtons.css';
import BookmarkDownload from '../shared/Bookmark/BookmarkDownload';

const Bookmark3 = ({ data }) => {
  const [downloadData, setDownloadData] = useState([]);

  useEffect(() => {
    const downloadHelpArray = Object.keys(data.download).map(
      el => data.download[el]
    );

    setDownloadData(downloadHelpArray);
  }, []);

  return (
    <>
      {!downloadData ? (
        <Indicator />
      ) : (
        <BookmarkWrapper>
          <DownloadTitle>{data.download.title}</DownloadTitle>
          {downloadData.slice(1).map(download => (
            <BookmarkDownload key={download.display_name} download={download} />
          ))}
        </BookmarkWrapper>
      )}
    </>
  );
};

export default Bookmark3;
