import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import { HideOnScroll } from 'm-web-components';

import useWindowSize from 'hooks/useWindowSize';
import { desktopSize } from 'helpers/contans';

import Menu from 'components/Menu/Menu';
import { Wrapper, Nav } from './Header.css';
import HeaderTop from './HeaderTop';
import HeaderButtons from './HeaderButtons';

const Header = ({ changeZoom }) => {
  const [menuIsOpen, setMenuOpen] = useState(false);
  const [width] = useWindowSize();
  const { pathname } = useLocation();

  useEffect(() => {
    setMenuOpen(false);
  }, [pathname]);

  return (
    <Wrapper menuisopen={menuIsOpen}>
      <HideOnScroll onWidthOff={desktopSize}>
        <AppBar
          color='white'
          style={{ height: `${width < desktopSize ? '70px' : 'auto'}` }}
        >
          <HeaderTop
            isOpen={menuIsOpen}
            setOpen={setMenuOpen}
            changeZoom={changeZoom}
          />
          <Nav menuisopen={menuIsOpen}>
            <Menu />
            {width < desktopSize && <HeaderButtons changeZoom={changeZoom} />}
          </Nav>
        </AppBar>
      </HideOnScroll>
    </Wrapper>
  );
};

export default Header;
