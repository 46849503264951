import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { device } from 'styles/devices';

import { apiEndpoints, sendRequest } from 'API';
import Indicator from 'components/atoms/Indicator';
import PageTitle from 'components/atoms/PageTitle';
import Section from 'components/shared/Section/Section';
import { useLocation, useParams } from 'react-router-dom';

const Wrapper = styled.main`
  padding: 0px;
  margin: 0 auto;

  @media ${device.desktop} {
    padding: 20px;
  }
`;

const PageTheme = () => {
  const [dataPage, setDataPage] = useState(null);
  const [sectionsList, setSectionList] = useState([]);
  const { pathname } = useLocation();
  const { slug } = useParams();

  useEffect(() => {
    const get = async () => {
      const { data } = await sendRequest.get(
        apiEndpoints.page(slug || pathname)
      );
      setDataPage(data[0]?.acf);
      setSectionList(
        Object.values(data[0]?.acf)
          .splice(1)
          .map(el => el)
      );
    };
    get();
  }, [pathname]);

  return (
    <Wrapper>
      {!dataPage ? (
        <Indicator />
      ) : (
        <>
          <PageTitle content={dataPage?.title} />
          {sectionsList.map((section, index) => (
            <Section key={section.subtitle} {...section} right={index % 2} />
          ))}
        </>
      )}
    </Wrapper>
  );
};

export default PageTheme;
