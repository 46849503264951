import React, { useEffect, useState } from 'react';

import LogoFEU from 'assets/logo/Logo_Fundusze_EU.png';
import LogoL from 'assets/logo/Logo_Lubelskie.png';
import LogoRP from 'assets/logo/Logo_RP.png';
import LogoUEU from 'assets/logo/Logo_Unia_EU.png';

import { ButtonPrimary } from 'components/atoms/Button';
import { apiEndpoints, sendRequest } from 'API';
import {
  Image,
  Wrapper,
  ButtonContainer,
  LogosContainer,
} from './FooterTop.css';

const FooterTop = () => {
  const [link, seLink] = useState([]);

  useEffect(() => {
    const get = async () => {
      const { data } = await sendRequest.get(apiEndpoints.mainMenu);
      const url = data.items.filter(el => el.post_name === 'ebok')[0];
      seLink(url?.url);
    };

    get();
  }, []);

  return (
    <Wrapper>
      <ButtonContainer>
        <p>Monitoruj swoje rozliczenia online</p>
        <a href={link}>
          <ButtonPrimary>ZALOGUJ SIĘ</ButtonPrimary>
        </a>
      </ButtonContainer>
      <LogosContainer>
        <Image href='https://www.funduszeeuropejskie.gov.pl/'>
          <img src={LogoFEU} alt='logo - fundusze UE' />
        </Image>
        <Image href='https://lubuskie.pl/'>
          <img src={LogoL} alt='logo - Lubuskie' />
        </Image>
        <Image href='https://www.gov.pl/'>
          <img src={LogoRP} alt='logo - RP' />
        </Image>
        <Image href='https://europa.eu/european-union/index_pl'>
          <img src={LogoUEU} alt='logo - Unia EU' />
        </Image>
      </LogosContainer>
    </Wrapper>
  );
};

export default FooterTop;
