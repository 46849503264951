import React, { useEffect, useRef, useState } from 'react';

import { apiEndpoints, sendRequest } from 'API';
import Section1 from 'components/Home/Section1';
import Section2 from 'components/Home/Section2';
import Indicator from 'components/atoms/Indicator';
import Informator from 'components/Informator/Informator';
import PageTitle from 'components/atoms/PageTitle';

const Home = () => {
  const [dataPage, setDataPage] = useState(null);
  const [posts, setPosts] = useState(null);

  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  useEffect(() => {
    const get = async () => {
      const { data } = await sendRequest.get(
        apiEndpoints.page('strona-glowna')
      );
      setDataPage(data);

      const { data: postsData } = await sendRequest.get(apiEndpoints.posts);
      setPosts(postsData.splice(0, 3));
    };
    get();
  }, []);

  return (
    <main>
      {!dataPage ? (
        <Indicator />
      ) : (
        <>
          <Section1 data={dataPage[0]?.acf} executeScroll={executeScroll} />
          {/* helper to scroll */}
          <div ref={myRef} />
          <Section2 data={dataPage[0]?.acf} />
          <PageTitle content='Najnowsze aktualności' />
          {posts && <Informator posts={posts} />}
        </>
      )}
    </main>
  );
};

export default Home;
