import React from 'react';
import styled from 'styled-components';
import { Sling as Hamburger } from 'hamburger-react';

import TerespolLogo from 'assets/logo/terespol_logo.png';

import { device } from 'styles/devices';
import { desktopSize } from 'helpers/contans';
import useWindowSize from 'hooks/useWindowSize';
import HeaderButtons from './HeaderButtons';

const Wrapper = styled.div`
  display: flex;
  padding: 10px 40px;
  justify-content: space-between;
`;

const Logo = styled.img`
  max-width: 120px;
  max-height: 55px;

  @media ${device.desktop} {
    max-width: 160px;
    max-height: 150px;
  }
`;

const HeaderTop = ({ isOpen, setOpen, changeZoom }) => {
  const [width] = useWindowSize();

  return (
    <Wrapper>
      <Logo src={TerespolLogo} alt='Logo Terespolu' />
      {width > desktopSize && <HeaderButtons changeZoom={changeZoom} />}

      {width < desktopSize && (
        <Hamburger toggled={isOpen} toggle={setOpen} color='#0A2041' />
      )}
    </Wrapper>
  );
};

export default HeaderTop;
