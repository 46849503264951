import styled from 'styled-components';

import { device } from 'styles/devices';
import { centerFlex } from 'styles/mixins';
import DotsImg from 'assets/images/kropeczki.png';

export const Wrapper = styled.div`
  padding: 20px;
  margin-top: 100px;

  p {
    color: white;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }
`;

export const ButtonContainer = styled.div`
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.primary};
  min-height: 250px;
  padding: 50px;
  border-radius: 1vw;
  margin-bottom: 70px;

  ${centerFlex}
  flex-direction: column;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -30%);
    height: 80%;
    width: 30%;

    background: url('${DotsImg}') no-repeat left top;
    background-size: cover;
    z-index: -1;
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media ${device.desktop} {
    padding: 0 100px;
  }
`;

export const Image = styled.a`
  margin: 20px;
  display: block;

  @media ${device.desktop} {
    width: 34%;
  }

  @media ${device.desktopL} {
    width: auto;
    margin: 20px;
  }

  img {
    width: 100%;
  }
`;
