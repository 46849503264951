import React from 'react';
import styled from 'styled-components';

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const List = ({ data, icon }) => {
  const list = data
    .replace('<p>', '')
    .replace('</p>', '')
    .split('<br />')
    .map(el => (
      <ListItem key={el}>
        {icon}
        {el}
      </ListItem>
    ));

  return <ul>{list}</ul>;
};

export default List;
