import { css } from 'styled-components';
import { device } from 'styles/devices';

import Download from 'assets/icons/file_download.svg';

export const PostStyles = css`
  //galeria
  .wp-block-gallery {
    margin: 0;
  }

  .blocks-gallery-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
    margin: 0;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
    align-content: space-around;

    li {
      background: none;
      padding: 0;
      margin: 10px 0;
    }

    figure {
      width: 100%;
      margin: 0;
    }

    img {
      width: 100%;

      height: 300px;
      object-fit: cover;
    }

    @media ${device.tablet} {
      li {
        width: 50%;
        padding: 10px;
      }
    }

    @media ${device.desktop} {
      li {
        width: 33%;
      }
    }
  }

  // tekst + obrazek

  .wp-block-media-text {
    img {
      width: 100%;
      height: auto;
    }

    @media ${device.tablet} {
      display: flex;
      align-items: center;

      .wp-block-media-text__content {
        width: 50%;
      }

      figure {
        margin: 0;
        margin-right: 20px;
        width: 50%;
      }
    }
  }

  .wp-block-media-text.has-media-on-the-right {
    @media ${device.tablet} {
      flex-direction: row-reverse;

      figure {
        margin-right: 0;
        margin-left: 20px;
      }
    }
  }

  //pliki

  .wp-block-file {
    margin-top: 50px;

    a:nth-child(1) {
      display: block;
    }

    a:nth-child(2) {
      display: block;
      background: url('${Download}') no-repeat left top;
      padding-left: 50px;
      text-transform: uppercase;
      font-weight: 600;
      margin: 5px 5px 30px;
    }
  }
`;
