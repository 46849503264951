import React from 'react';
import styled from 'styled-components';
import { device } from 'styles/devices';
import WYSIWYG from 'components/shared/WYSIWYG/WYSIWYG';

const Wrapper = styled.section`
  margin-bottom: 50px;

  @media ${device.tablet} {
    flex-direction: ${({ right }) => (right === 1 ? 'row' : 'row-reverse')};
    display: ${({ noFlex }) => (noFlex ? 'block' : 'flex')};
    align-items: center;
  }
`;

const TextEditor = styled.article`
  @media ${device.tablet} {
    width: 50%;
    padding-left: ${({ right }) => right && '50px'};
    padding-right: ${({ right }) => !right && '50px'};
  }
`;

const Image = styled.div`
  margin: 50px 0;

  img {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 50%;
    margin: 0;
    padding: 0 50px;

    img {
      width: 100%;
    }
  }
`;

const Section = ({ right, photo, desc, subtitle }) => (
  <Wrapper right={right}>
    <Image>
      <img src={photo?.url} alt={photo?.alt || 'problem ze zdjęciem'} />
    </Image>
    <TextEditor right={right}>
      <h2>{subtitle}</h2>
      <WYSIWYG content={desc} />
    </TextEditor>
  </Wrapper>
);

export default Section;
