/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';
import { apiEndpoints, sendRequest } from 'API';
import parse from 'html-react-parser';

import ContactTiles from 'components/Contact/ContactTiles';
import Indicator from 'components/atoms/Indicator';
import {
  ContactWrapper,
  ContactTitle,
  ContactSubtitle,
  ContactData,
  ContactTilesContainer,
  WrapperMap,
} from 'components/Contact/Contact.css';

const Contact = () => {
  const [dataPage, setDataPage] = useState(null);

  useEffect(() => {
    const get = async () => {
      const { data } = await sendRequest.get(apiEndpoints.page('kontakt'));
      setDataPage(data[0]?.acf);
    };
    get();
  }, []);

  return (
    <main>
      {!dataPage ? (
        <Indicator />
      ) : (
        <ContactWrapper>
          <ContactTitle>{dataPage.title}</ContactTitle>
          <ContactSubtitle>{dataPage.subtitle_1}</ContactSubtitle>
          <ContactData>{parse(dataPage.data_1)}</ContactData>

          <WrapperMap>
            <iframe
              title='google-map-1'
              width='100%'
              height='500'
              src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d39234.20420045899!2d23.609461!3d52.077222!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47210af894713277%3A0xf184460094c247f0!2sGraniczna%208%2C%2021-550%20Terespol%2C%20Poland!5e0!3m2!1sen!2sus!4v1616082832913!5m2!1sen!2sus'
              allowfullscreen=''
              loading='lazy'
            />
          </WrapperMap>

          <ContactData>Stacja Uzdatniania wody, ul. Kodeńska</ContactData>

          <WrapperMap>
            <iframe
              title='google-map-2'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2452.466717034922!2d23.60911131596717!3d52.07123327714143!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTLCsDA0JzE2LjQiTiAyM8KwMzYnNDAuNyJF!5e0!3m2!1sen!2spl!4v1616082790228!5m2!1sen!2spl'
              width='100%'
              height='500'
              allowfullscreen=''
              loading='lazy'
            />
          </WrapperMap>

          <ContactData>Miejska Oczyszczalnia Ścieków</ContactData>

          <WrapperMap>
            <iframe
              title='google-map-3'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1225.961085650603!2d23.63384465832695!3d52.08114664327161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTLCsDA0JzUyLjEiTiAyM8KwMzgnMDUuOCJF!5e0!3m2!1sen!2spl!4v1617979387042!5m2!1sen!2spl'
              width='100%'
              height='500'
              allowfullscreen=''
              loading='lazy'
            />
          </WrapperMap>

          <ContactSubtitle>{dataPage.subtitle_2}</ContactSubtitle>
          <ContactData>{parse(dataPage.data_2)}</ContactData>
          <ContactTilesContainer>
            <ContactTiles data={dataPage.contact_tiles} />
          </ContactTilesContainer>
        </ContactWrapper>
      )}
    </main>
  );
};

export default Contact;
