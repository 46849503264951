import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

    * {
    /*temporary helper
    uncomment to see borders of every HTML element on the website, helps with positioning*/
    /* border: 1px blue solid;  */
    }

    *, *:before, *:after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
        color: #0A2041;

    }

    body, html {
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        width: 100vw;
        scroll-behavior: smooth;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
    }
`;

export default GlobalStyle;
