import axios from 'axios';

export const sendRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' },
});

sendRequest.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 404) {
      window.location.href = '/404';
    }
    throw error;
  }
);

export const apiEndpoints = {
  menus: '/wp-json/menus/v1/menus',
  mainMenu: '/wp-json/menus/v1/menus/glowne',

  pages: '/wp-json/wp/v2/pages',
  page: slug => `/wp-json/wp/v2/pages?slug=${slug}`,

  posts: '/wp-json/wp/v2/posts',
  post: id => `/wp-json/wp/v2/posts/${id}`,

  searchPosts: value => `/wp-json/wp/v2/posts?search=${value}`,
  searchPages: value => `/wp-json/wp/v2/pages?search=${value}`,
  media: value => `https://wp1.mayeryn.com/wp-json/wp/v2/media?parent=${value}`,
};
