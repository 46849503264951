import React, { useEffect, useState } from 'react';
import { apiEndpoints, sendRequest } from 'API';
import SimpleElementInMenu from './SimpleMenuItems';
import { Wrapper } from './Menu.css';
import { CategoryInMenu } from './CategoryInMenu,';
import CategoryInMenuFooter from './CategoryInMenuFooter';

const Menu = ({ footer }) => {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const get = async () => {
      const { data } = await sendRequest.get(apiEndpoints.mainMenu);
      setMenuItems(data.items);
    };

    get();
  }, []);

  const menuList = menuItems.map(el => {
    const url = el.url.includes('strony/')
      ? `strony/${el.slug}`
      : el.slug || el.url;

    if (el.child_items) {
      if (footer) {
        return (
          <CategoryInMenuFooter
            key={el.title}
            title={el.title}
            items={el.child_items}
          />
        );
      }
      return (
        <CategoryInMenu
          key={el.title}
          title={el.title}
          items={el.child_items}
        />
      );
    }
    if (el.slug === 'strona-glowna') {
      return (
        <SimpleElementInMenu
          key={el.title}
          title={el.title}
          url=''
          footer={footer}
        />
      );
    }

    return (
      <SimpleElementInMenu
        key={el.title}
        title={el.title}
        url={url}
        noSlug={el.slug ? 'false' : 'true'}
        footer={footer}
      />
    );
  });

  return <Wrapper footer={footer}>{menuList}</Wrapper>;
};

export default Menu;
