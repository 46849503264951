import React, { useEffect, useState } from 'react';

import { apiEndpoints, sendRequest } from 'API';
import Section1 from 'components/AboutUs/Section1';
import Section2 from 'components/AboutUs/Section2';
import Section3 from 'components/AboutUs/Section3';
import Indicator from 'components/atoms/Indicator';
import PageTitle from 'components/atoms/PageTitle';

import styled from 'styled-components';
import { device } from 'styles/devices';

export const AboutUsWrapper = styled.div`
  padding: 0px;
  @media ${device.desktop} {
    padding: 20px;
  }
`;

const AboutUs = () => {
  const [dataPage, setDataPage] = useState(null);

  useEffect(() => {
    const get = async () => {
      const { data } = await sendRequest.get(apiEndpoints.page('o-nas'));
      setDataPage(data);
    };
    get();
  }, []);

  return (
    <main>
      {!dataPage ? (
        <Indicator />
      ) : (
        <AboutUsWrapper>
          <PageTitle content={dataPage[0]?.acf.title} />
          <Section1 data={dataPage[0]?.acf} />
          <Section2 data={dataPage[0]?.acf} />
          <Section3 data={dataPage[0]?.acf} />
        </AboutUsWrapper>
      )}
    </main>
  );
};

export default AboutUs;
