import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import slugify from 'slugify';

const Wrapper = styled.div`
  box-shadow: 2px 2px 20px rgba(10, 32, 65, 0.2);
  padding: 10px;
  margin: 30px 10px;
  height: 220px;
  width: 240px;
  padding: 15px;
  transition: 0.2s;

  background-color: ${({ theme }) => theme.primary};

  :hover {
    transform: scale(1.05);
  }
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 16px;
  color: white;
  text-align: center;
`;

const Image = styled.img``;

const LinkSyled = styled(Link)`
  display: block;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
`;

const SingleActivity = ({ title }) => {
  const [image, setImage] = useState('');
  const slug = slugify(title, { lower: true });

  const getImage = async () => {
    const img = await import(`assets/icons/${slug}.svg`);
    setImage(img.default);
  };

  useEffect(() => {
    getImage();
  }, []);

  return (
    <Wrapper>
      <LinkSyled to={slug}>
        <Image src={image} alt={`ikona działalności-${title}`} />
        <Title>{title}</Title>
      </LinkSyled>
    </Wrapper>
  );
};

export default SingleActivity;
