import React from 'react';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import parse from 'html-react-parser';

import List from 'components/atoms/List';
import { SectionWrapper, Image, TextContainer } from '../Home/Home.css';

const Section2 = ({ data }) => (
  <SectionWrapper>
    <TextContainer left>
      <p>{parse(data.s2_description)}</p>
      <List data={data.s2_list} icon={<ArrowRightIcon />} />
    </TextContainer>
    <div>
      <Image src={data.s2_photo?.url} alt={data.s2_photo?.alt} />
    </div>
  </SectionWrapper>
);

export default Section2;
