import React from 'react';
import styled from 'styled-components';

import Arrow from 'assets/icons/arrow.svg';

import { SectionWrapper } from '../Home/Home.css';

const List = styled.div`
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
`;

const ListItem = styled.li`
  list-style: none;
  background: url('${Arrow}') no-repeat left top;
  padding: 7px 0px 10px 35px;
  margin: 0;
`;

const Section3 = ({ data }) => {
  const memberOfManagement = data.s3_list
    .split('<br />')
    .map(member => <ListItem>{member}</ListItem>);

  return (
    <SectionWrapper noFlex>
      <h2>{data.s3_title}</h2>
      <List>{memberOfManagement}</List>
    </SectionWrapper>
  );
};

export default Section3;
