import React from 'react';
import styled from 'styled-components';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import List from 'components/atoms/List';
import { SectionWrapper, TextContainer } from '../Home/Home.css';

const WrapperImages = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  div {
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  img {
    width: 100%;
    margin: 20px;
  }
`;

const Section1 = ({ data }) => (
  <SectionWrapper>
    <WrapperImages>
      <div>
        <img src={data.s1_photo1?.url} alt={data.s1_photo1?.alt} />
        <img src={data.s1_photo2?.url} alt={data.s1_photo2?.alt} />
      </div>
      <div>
        <img src={data.s1_photo3?.url} alt={data.s1_photo3?.alt} />
      </div>
    </WrapperImages>
    <TextContainer right>
      <h2>{data.s1_title}</h2>
      <p>{data.s1_description}</p>
      <List data={data.s1_list} icon={<ArrowRightIcon />} />
    </TextContainer>
  </SectionWrapper>
);

export default Section1;
