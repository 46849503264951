import styled from 'styled-components';
import { device } from 'styles/devices';

export const Date = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.textGray};
  margin-bottom: -5px;

  @media ${device.tablet} {
    font-size: 16px;
    margin-bottom: -15px;
  }
`;
