import styled from 'styled-components';
import { device } from 'styles/devices';

// Bookmarks

export const BookmarkWrapper = styled.div`
  display: block;
`;

export const BookmarkDescription = styled.div`
  margin-top: 40px;
  width: 100%;
  text-align: left;
`;

export const BookmarkTableTitle = styled.h2`
  margin-top: 100px;
  font-size: 20px;
  font-weight: 600;
  @media ${device.desktop} {
    font-size: 40px;
  }
`;

export const BookmarkTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  tr > td:nth-child(1) {
    width: 40%;
  }
  @media ${device.desktop} {
    width: 70%;
    min-width: 755px;
    tr > td:nth-child(1) {
      width: 60%;
    }
  }
`;

export const TableDataHeader = styled.td`
  min-height: 50px;
  height: 50px;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #e5e5e5;
  vertical-align: middle;
  text-align: ${({ align }) => align || `right`};
  background-color: #e7e9ec;
  @media ${device.mobileS} {
    font-size: 14px;
  }
  @media ${device.desktop} {
    padding: 16px;
  }
`;

export const TableData = styled.td`
  min-height: 50px;
  height: 50px;
  padding: 8px;
  font-size: 14px;
  font-weight: ${({ weight }) => weight || `400`};
  border: 1px solid #e5e5e5;
  vertical-align: middle;
  text-align: ${({ align }) => align || `right`};
  @media ${device.desktop} {
    padding: 16px;
  }
`;

export const DownloadContainer = styled.div`
  display: flex;
`;

export const BookmarkSubtitle = styled.div`
  margin: auto;
  margin-top: 40px;
  width: 70%;
  text-align: center;
  font-weight: 600;
`;

// Page - PraceList

export const PriceListsWrapper = styled.div`
  padding: 0px;
  @media ${device.desktop} {
    padding: 20px;
  }
`;

export const PriceListsTitle = styled.h1`
  font-size: 28px;
  font-weight: 600;

  @media ${device.desktop} {
    font-size: 48px;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-bottom: 1.5px solid #e5e5e5;
  @media ${device.desktop} {
    flex-direction: row;
  }
`;
