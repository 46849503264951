import styled from 'styled-components';
import { device } from 'styles/devices';

export const BookmarkWrapper = styled.div`
  margin-top: 40px;
  display: block;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-bottom: 1.5px solid #e5e5e5;
  @media ${device.desktop} {
    flex-direction: row;
  }
`;

export const BookmarkButtons = styled.button`
  height: ${({ height }) => height};
  width: 100%;
  color: ${({ theme }) => theme.fontColor};
  background: ${({ theme }) => theme.bgColor};
  border: 1.5px solid #e5e5e5;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
  padding: 16px;

  &:hover {
    background-color: ${({ theme }) => theme.hoverColor};
  }

  @media ${device.desktop} {
    width: 374px;
    font-size: 16px;
  }
`;

export const DownloadTitle = styled.h3`
  margin-top: 100px;
  font-size: 18px;
  font-weight: 600;
`;

export const DownloadGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  font-size: 14px;
  font-weight: 400;
  a {
    display: flex;
    align-items: center;
    font-weight: 600;
    text-decoration: none;
    color: inherit;
    margin-top: 10px;
    img {
      max-width: 18px;
      max-height: 18px;
      margin-right: 8px;
    }
  }
`;

export const theme = {
  fontColor: '#0A2041',
  bgColor: 'white',
  hoverColor: '#e5e5e5',
};

export const invertedTheme = {
  fontColor: theme.bgColor,
  bgColor: theme.fontColor,
  hoverColor: theme.fontColor,
};
