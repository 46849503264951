import styled, { css } from 'styled-components';
import { device } from 'styles/devices';

import { centerFlex } from 'styles/mixins';

export const Wrapper = styled.ul`
  padding-bottom: ${({ footer }) => (footer ? 0 : '70px')};

  @media ${device.desktop} {
    display: ${({ footer }) => (footer ? 'block' : 'flex')};
    padding: 10px 40px 0;
    justify-content: center;
  }
`;

export const NavItem = styled.li`
  position: relative;
  display: block;
  flex-grow: 1;
  height: 52px;
  font-size: 20px;

  @media ${device.desktop} {
    height: 48px;
    max-width: 200px;
  }

  a {
    height: 100%;
    width: 100%;
    text-decoration: none;
    display: block;
    font-weight: 600;
    transition: 0.5s;
    padding-left: 30px;
    display: flex;
    align-items: center;

    :hover {
      background-color: ${({ theme }) => theme.hoverBg};
    }

    &.active {
      font-weight: 700;
      border-bottom: 3px solid ${({ theme }) => theme.primary};
    }

    @media ${device.desktop} {
      text-align: center;
      padding-left: 0;
      height: 48px;
      font-size: 14px;
      justify-content: center;
    }
  }
`;

export const ContainerCategory = styled.ul`
  position: absolute;
  left: 0;
  max-height: ${({ hover }) => (hover ? 'auto' : 0)};
  overflow: hidden;
  transition: 0.1s;
  transform: ${({ hover }) => (hover ? 'scale(1)' : 'scale(0)')};
  opacity: ${({ hover }) => (hover ? '1' : '0')};
  transform-origin: top;
  font-weight: 600;
  background-color: white;
  box-shadow: 0px 0px 4px -1px rgb(0 0 0 / 10%), 0px 0px 5px 0px rgb(0 0 0 / 7%),
    0px 0px 10px 0px rgb(0 0 0 / 5%);

  flex-direction: column;
  z-index: 1200;
  width: 100%;

  @media ${device.desktop} {
    max-width: 300px;
    min-width: 250px;
    width: auto;
  }

  @media ${device.desktop} {
    padding: 0;
  }

  li {
    height: auto;
    font-size: 16px;

    @media ${device.desktop} {
      max-width: 250px;
      font-size: 14px;
    }

    a {
      padding: 20px 10px;
      padding-left: 60px;
      height: auto;
      text-align: left;
      justify-content: flex-start;

      @media ${device.desktop} {
        padding: 15px 20px;
      }
    }
  }
`;

export const CategoryTitle = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 20px;
  height: 52px;
  padding-left: 30px;

  ${({ isactive }) =>
    isactive &&
    css`
      font-weight: 700;
      border-bottom: 3px solid ${({ theme }) => theme.primary};
    `};

  @media ${device.desktop} {
    text-align: center;
    padding-left: 0;
    height: 48px;
    font-size: 14px;

    ${centerFlex}
  }
`;
