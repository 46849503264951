import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

import { device } from 'styles/devices';

const PageTitleStyled = styled.h1`
  font-size: 22px;

  @media ${device.mobileL} {
    font-size: 30px;
  }

  @media ${device.tablet} {
    font-size: 38px;
  }

  @media ${device.desktopL} {
    font-size: 48px;
  }
  button {
    margin: 50px 0;
  }
`;

const PageTitle = ({ content }) => (
  <PageTitleStyled>{parse(content)}</PageTitleStyled>
);

export default PageTitle;
