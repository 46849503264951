import React from 'react';
import DownloadImg from 'assets/icons/file_download.svg';
import { DownloadGroup } from './BookmarkButtons.css';

const BookmarkDownload = data => (
  <DownloadGroup>
    {data.download?.display_name}
    <a href={data.download?.file?.url} download>
      <img src={DownloadImg} alt='Ikona pobierania pliku' />
      {data.download?.download_text}
    </a>
  </DownloadGroup>
);

export default BookmarkDownload;
