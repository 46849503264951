import React from 'react';
import parse from 'html-react-parser';

import SingleActivity from 'components/shared/Tile/SingleActivity';
import { SectionWrapper, TextContainer, ListActivities } from './Home.css';

const Section2 = ({ data }) => {
  const titlesActivities = data?.s2_titles_of_activities.split('<br />');
  const listActivities = titlesActivities.map((el, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <SingleActivity key={index} title={el} />
  ));

  return (
    <SectionWrapper noFlex>
      <TextContainer>
        <h2>{data.s2_title}</h2>
        <p>{parse(data.s2_description)}</p>
      </TextContainer>
      <ListActivities>{listActivities}</ListActivities>
    </SectionWrapper>
  );
};

export default Section2;
