import React from 'react';
import parse from 'html-react-parser';
import styled, { css } from 'styled-components';
import { device } from 'styles/devices';

import Arrow from 'assets/icons/arrow.svg';
import { PostStyles } from './PostStyles';

const Wrapper = styled.div`
  ul {
    margin-left: 20px;
    li {
      background: url('${Arrow}') no-repeat left top;
      padding: 7px 0px 7px 30px;
      margin: 0;

      ul {
        li {
          background: none;
          list-style: inside;
          padding: 3px 0px 3px 0;
        }
      }
    }

    ${({ defaultBullet }) =>
      defaultBullet &&
      css`
        list-style: disc;
        li {
          background: none;
          padding: 7px 0px 7px 5px;
        }
      `}

    ${({ document }) =>
      document &&
      css`
        list-style: '-';
        li {
          background: none;
          padding: 7px 0px 7px 5px;
        }
      `}
  }

  ol {
    li {
      padding: 7px 0px 7px 30px;
    }
  }

  p,
  li {
    font-size: 14px;
  }

  h2 {
    margin-bottom: 40px;
    font-weight: 600;
    font-size: 18px;
  }

  h3 {
    margin-top: 40px;
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 18px;
  }

  @media ${device.mobileL} {
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 16px;
    }
  }

  @media ${device.tablet} {
    h1 {
      font-size: 38px;
    }
  }

  @media ${device.desktop} {
    ul {
      margin-left: 30px;
    }
    flex-direction: row;
    align-items: center;
  }

  @media ${device.desktopL} {
    h1 {
      font-size: 48px;
    }
  }

  ${PostStyles}
`;

const WYSIWYG = ({ content, document, defaultBullet }) => (
  <Wrapper id='WYSIWYG' document={document} defaultBullet={defaultBullet}>
    {parse(content)}
  </Wrapper>
);

export default WYSIWYG;
