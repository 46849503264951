import styled from 'styled-components';

import { device } from 'styles/devices';

export const Wrapper = styled.div`
  @media ${device.desktop} {
    .MuiPaper-elevation4 {
      box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 10%),
        0px 4px 5px 0px rgb(0 0 0 / 7%), 0px 1px 10px 0px rgb(0 0 0 / 5%);
    }
  }
`;

export const Nav = styled.nav`
  background-color: white;
  transition: 0.3s;
  transform: ${({ menuisopen }) =>
    menuisopen ? 'translateY(0)' : 'translateY(-120%)'};

  box-shadow: 0px 4px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 7%),
    0px 4px 10px 0px rgb(0 0 0 / 5%);

  @media ${device.desktop} {
    min-height: 0;
    transform: translateY(0);
  }
`;
