import React, { useEffect, useState } from 'react';

import List from 'components/atoms/List';
import Indicator from 'components/atoms/Indicator';
import { Title, ListItem } from './Contact.css';

const ContactTiles = ({ data }) => {
  const [tiles, setTiles] = useState(null);

  useEffect(() => {
    const helpArray = Object.keys(data).map(el => data[el]);

    setTiles(helpArray);
  }, [data]);

  return (
    <>
      {!tiles ? (
        <Indicator />
      ) : (
        <div>
          {tiles.map(el => (
            <ListItem>
              <Title>{el.title}</Title>
              <List data={el.data} />
            </ListItem>
          ))}
        </div>
      )}
    </>
  );
};

export default ContactTiles;
