import styled, { css } from 'styled-components';
import { device } from 'styles/devices';

export const SectionWrapper = styled.section`
  display: ${({ noFlex }) => (noFlex ? 'block' : 'flex')};
  flex-direction: ${({ left }) => (left ? 'column-reverse' : 'column')};
  padding: 20px;
  margin-bottom: 50px;

  p,
  li {
    font-size: 14px;
  }

  h2 {
    margin-bottom: 40px;
  }

  @media ${device.mobileL} {
    h1 {
      font-size: 30px;
    }
    div {
      font-size: 18px;
    }
  }

  @media ${device.tablet} {
    h1 {
      font-size: 38px;
    }
    div {
      font-size: 16px;
    }
  }

  @media ${device.desktop} {
    flex-direction: row;
    align-items: center;
  }

  @media ${device.desktopL} {
    h1 {
      font-size: 48px;
    }
    button {
      margin: 50px 0;
    }
  }
`;

export const ImageWrapper = styled.div`
  margin-top: 30px;
  min-width: 200px;

  @media ${device.desktop} {
    max-width: 50%;
  }

  .slick-dots {
    transform: translateY(-20px);

    @media ${device.desktop} {
      transform: translateY(0px);
    }

    @media ${device.desktopL} {
      transform: translateY(60px);
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  margin: 30px 0;
  object-fit: object;
  min-height: 300px;

  @media ${device.desktop} {
    padding: 0 10vw;
  }

  ${({ main }) =>
    main &&
    css`
      width: 100vw;
      margin-left: -40px;
      max-height: 45vh;
      margin-bottom: 20px;
    `}

  @media ${device.desktop} {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

export const TextContainer = styled.div`
  @media ${device.desktop} {
    flex-grow: 1;
    max-width: 60%;
    min-width: 50%;
    padding-left: ${({ right }) => right && '5vw'};
    padding-right: ${({ left }) => left && '5vw'};
  }
`;

export const ListActivities = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  margin: 0 auto;
`;
