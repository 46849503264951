import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { apiEndpoints, sendRequest } from 'API';
import Indicator from 'components/atoms/Indicator';
import WYSIWYG from 'components/shared/WYSIWYG/WYSIWYG';
import PageTitle from 'components/atoms/PageTitle';
import { ButtonPrimary } from 'components/atoms/Button';
import { getDate } from 'helpers/getDate';

import { Date } from './Informator.css';

const Wrapper = styled.div`
  padding: 30px 0;
  margin-bottom: 100px;
`;

const PagesButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-right: 10px;
  }
`;

const SingleInfo = () => {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [dataPage, setDataPage] = useState(null);
  const [activePage, setActivePage] = useState(0);
  const [date, setDate] = useState(null);

  useEffect(() => {
    const get = async () => {
      const { data } = await sendRequest.get(apiEndpoints.post(id));
      const pages = data.content.rendered.split(
        `<hr class="wp-block-separator"/>`
      );
      setDate(data.date);
      setTitle(data.title.rendered);
      setDataPage(pages);
    };
    get();
  }, []);

  return (
    <Wrapper>
      {!dataPage ? (
        <Indicator />
      ) : (
        <>
          <Date>{getDate(date)}</Date>
          <PageTitle content={title} />
          <WYSIWYG content={dataPage[activePage]} />
          {dataPage.length > 1 && (
            <PagesButtons>
              {dataPage.map((el, i) => (
                <ButtonPrimary key={el} onClick={() => setActivePage(i)}>
                  {i + 1}
                </ButtonPrimary>
              ))}
            </PagesButtons>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default SingleInfo;
